import _error                    from "_common/error";
import XHR                       from "_common/xhr";
import { formatD1RetentionData } from "_components/pages/growthDashboard/utils";
import { configs }               from "_config/index";

export const captureUserVisitingSession = async () => {
	const result = await XHR.get ( {
		url: `${ configs.USER_API_URL }user-profile/captureSession`
	}, {
		emptyResponse: true
	} );

	return result;
};

export const getGrowthStats = async ( { userId, startDate, endDate, type } ) => {

	const res = await XHR.get ( {
		url: `${ configs.USER_API_URL }user-profile/${ userId }/${ type }?startDate=${ startDate }&endDate=${ endDate }`
	} );

	if ( type === "d1Data" ) {
		return { data: formatD1RetentionData ( res ), average: res.averageD1Percentage };
	}

	return { data: res || [], average: null };
};

export const getUserProfile = async function ( userData, queryParams ) {
	if ( !userData.userId )
		return;

	const response = await XHR.get.bind ( this ) ( {
		url    : `${ configs.USER_API_URL }user-profile/${ userData.userId }`,
		params : queryParams ? queryParams : {}

	} );

	return response;
};

export const getUserProfileByHandle = async function ( handle, queryParams ) {
	const response = await XHR.get.bind ( this ) ( {
		url    : `${ configs.USER_API_URL }user-profile/profileHandle/${ handle }`,
		params : queryParams ? queryParams : {}
	} );

	return response;
};

export const updateUserProfile = async data => {
	const { sportsFanId, userProfileDetails } = data;
	// const locale                              = "en";

	// userProfileDetails.contentLocale = "en";

	const response = await XHR.put ( {
		url     : `${ configs.USER_API_URL }user-profile/${ sportsFanId }`,
		headers : {
			"Content-Type": "application/json"
		},
		data: {
			...userProfileDetails

		}
	} );

	return response;
};

export const onBoardUserProfile = async userProfileDetails => {
	const { id } = userProfileDetails;
	// const locale = "en";

	// userProfileDetails.contentLocale = "en";

	const response = await XHR.put ( {
		url     : `${ configs.USER_API_URL }user-profile/${ id }`,
		headers : {
			"Content-Type": "application/json"
		},
		data: {
			...userProfileDetails
			// locale
		}
	} );

	return response;
};

export const getUserProfileStats = async function ( { userAuthDetails } ) {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.USER_API_URL }user-profile/${ userAuthDetails.userId }/ugcStatsV2`
	} );

	return response;
};

export const toggleFollowUser = async ( { userId, action } ) => {
	const response = await XHR.put ( {
		url     : `${ configs.USER_API_URL }user-profile/${ userId }/${ action }`,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	if ( !response || response.action.toUpperCase () !== action.toUpperCase () || response.sportsFanId.toString () !== userId.toString () )
		throw _error ( "An error occured" );

	return response;
};

export const getUserProfilesList = async profileIds => {

	let idString = "";

	for ( let i = 0; i < profileIds.length; i ++ ) {

		if ( i < profileIds.length - 1 ) {
			idString += profileIds[i] + ",";
		} else {
			idString += profileIds[i];
		}
	}
	const response = await XHR.get ( {
		url    : `${ configs.USER_API_URL }user-profile/users`,
		params : {
			ids      : idString,
			ugcStats : true,
			follow   : true

		}
	} );

	return response;
};

export const getUserFollowing = async ( { userId, pageNo, pageSize } ) => {
	try {
		const response = await XHR.get ( {
			url: `${ configs.USER_API_URL }user-profile/${ userId }/following?pageNo=${ pageNo }&pageSize=${ pageSize }`
		} );

		return response;
	} catch ( error ) {
		return [];
	}
};

export const getUserFollowers = async ( { userId, pageNo, pageSize } ) => {
	try {
		const response = await XHR.get ( {
			url: `${ configs.USER_API_URL }user-profile/${ userId }/followers?pageNo=${ pageNo }&pageSize=${ pageSize }`
		} );

		return response;
	} catch ( error ) {
		return [];
	}
};

export const uploadImage = async ( { formData } ) => {
	const response = await XHR.post ( {
		url     : `${ configs.FEED_POPULATOR_URL }uploadS3`,
		headers : {
			"Content-Type": "multipart/form-data"
		},
		data: formData
	} );

	return response;
};

export const getBlockedUsersListAPI = async ( { pageNo, pageSize, userId } ) => {
	const result = await XHR.get ( {
		url: `${ configs.USER_API_URL }user-profile/${ userId }/blockedUsers?blockedByCurrentUser=true&pageSize=${ pageSize }&pageNo=${ pageNo }`
		// url: `${ configs.USER_API_URL }/user-profile/${ userId }/blockedUsers?userIds=true`
	} );

	return result?.blockedByCurrentUser || [];
};

export const blockUserFromChat = async ( { streamerUserId, broadcastSessionId, userId } ) => {
	const response = await XHR.put ( {
		url  : `${ configs.USER_API_URL }user-profile/${ userId }/BLOCK`,
		data : { streamerUserId, broadcastSessionId }
	} );

	return response;
};

export const getBlockedUsersIdsListAPI = async ( { userId } ) => {
	if ( !userId ) return [];

	const result = await XHR.get ( {
		url: `${ configs.USER_API_URL }user-profile/${ userId }/blockedUsers?userIds=true`
	} );

	return result?.idsBlockedByCurrentUser || [];
};

export const unblockUserAPI = async ( { userId } ) => {

	const result = await XHR.put ( {
		url: `${ configs.USER_API_URL }user-profile/${ userId }/UNBLOCK`
	} );

	return result?.status?.toUpperCase () === "UNBLOCK";
};

export const fullBlockList = async ( { userId } ) => {
	const result = await XHR.get ( {
		url: `${ configs.USER_API_URL }user-profile/${ userId }/blockedUsers?userIds=true`
	} );

	return result?.idsBlockedByCurrentUser ? result : { idsBlockedByCurrentUser: [], idsBlockedCurrentUser: [] };
};

export const getUserProfileFromBranch = async ( { url } ) => {
	const deepLinks = [ url ];

	const response = await XHR.post ( {
		url     : `${ configs.USER_API_URL }management/getDataByDeepLinks`,
		headers : {
			"Content-Type": "application/json"
		},
		data: {
			deepLinks
		}
	} );

	return response[0]?.data || {};
};

export const getStickerMagicChat = async ( { categoryId, pageNo, pageSize } ) => {
	const response = await XHR.get ( {
		url: `${ configs.USER_API_URL }marketplace/donationStickers?pageNo=${ pageNo }&pageSize=${ pageSize }&categoryId=${ categoryId }`

	} );

	return response;
};

export const donateStickerMagicChat = async donationData => {
	if ( !donationData ) return;
	const response = await XHR.post ( {
		url  : `${ configs.USER_API_URL }marketplace/donateSticker`,
		data : donationData
	} );

	return response;
};

export const getForMeNotificationsFeed = async ( { SK = "", pageSize, pageNo } ) => {
	const response = await XHR.get ( {
		url: `${ configs.GROOT_URL }ntab/tab/for-me?SK=${ encodeURIComponent ( SK ) }&pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return response;
};

export const getActivityNotificationsFeed = async ( { SK = "", pageNo, pageSize } ) => {
	const response = await XHR.get ( {
		url: `${ configs.GROOT_URL }ntab/tab/activity?SK=${ encodeURIComponent ( SK ) }&pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return response;
};

export const getUpdatesNotificationsFeed = async ( { SK = "", pageNo, pageSize } ) => {
	const response = await XHR.get ( {
		url: `${ configs.GROOT_URL }ntab/tab/updates-all?SK=${ encodeURIComponent ( SK ) }&pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return response;
};

export const getFeaturesNotificationsFeed = async ( { SK = "", pageNo, pageSize } ) => {
	const response = await XHR.get ( {
		url: `${ configs.GROOT_URL }ntab/tab/updates-nf?SK=${ encodeURIComponent ( SK ) }&pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return response;
};

export const getAnnouncementsNotificationsFeed = async ( { SK = "", pageNo, pageSize } ) => {
	const response = await XHR.get ( {
		url: `${ configs.GROOT_URL }ntab/tab/updates-an?SK=${ encodeURIComponent ( SK ) }&pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return response;
};

export const getNotificationCount = async () => {
	const response = await XHR.get ( {
		url: `${ configs.GROOT_URL }ntab/for-me/new-count`
	} );

	return response;
};

export const getNotificationSettings = async () => {
	const response = await XHR.get ( {
		url: `${ configs.GROOT_URL }ntab/settings`
	} );

	return response;
};

export const updateNotificationSettings = async params => {
	const response = await XHR.post ( {
		url  : `${ configs.GROOT_URL }ntab/settings`,
		data : params
	} );

	return response;
};

export const markAllReadNotifications = async () => {
	const response = await XHR.get ( {
		url: `${ configs.GROOT_URL }ntab/tab/for-me/read`
	} );

	return response;
};

export const markSingleNotificationAsRead = async params => {
	const response = await XHR.post ( {
		url  : `${ configs.GROOT_URL }ntab/metrics`,
		data : params
	} );

	return response;
};

export const verifyTVCode = async ( { code } ) => {

	const response = await XHR.post ( {
		url     : `${ configs.USER_API_URL }user-profile/validateTvCode`,
		headers : {
			"Content-Type": "application/json"
		},
		data: {
			code
		}
	} );

	return response;
};

export const getProfileHandleUsers = async function ( { pageNo = 1, pageSize = 10 } ) {

	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.USER_API_URL }user-profile/customProfileHandleUsers?pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return response;
};

export const getUserData = async res => {

	const response = await XHR.get ( {
		url: `https://www.googleapis.com/oauth2/v3/userinfo?alt=json&access_token=${ res.access_token }`
	}, { noHeaders: true } );

	return response;
};

export const getCurrencies = async ( { pageNo = 1, pageSize = 5 } ) => {
	const response = await XHR.get ( {
		url: `${ configs.USER_API_URL }marketplace/currencies?pageNo=${ pageNo }&pageSize=${ pageSize }`
	} );

	return response;
};

export const getPaytmPayoutModes = async () => {
	const response = await XHR.get ( {
		url: `${ configs.USER_API_URL }marketplace/paytmPayoutModes`
	} );

	return response;
};

export const sendPaytmDiamondRedeemPayoutRequest = async body => {

	const response = await XHR.post ( {
		url     : `${ configs.USER_API_URL }marketplace/paytmDiamondRedeemPayoutRequest`,
		headers : {
			"Content-Type": "application/json"
		},
		data: body
	} );

	return response;
};

export const getDiamondsRedeemPayoutRequest = async paytmPayoutId => {

	let url = `${ configs.USER_API_URL }marketplace/diamondsRedeemPayoutRequest/${ paytmPayoutId }`;

	const response = await XHR.get ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getDiamondsRedeemPayoutHistory = async params => {
	const {
		userId,
		pageNo,
		pageSize,
		payoutModeId = 1
	} = params;

	let url = `${ configs.USER_API_URL }marketplace/diamondsRedeemPayoutRequests/${ userId }?pageNo=${ pageNo }&pageSize=${ pageSize }&payoutModeId=${ payoutModeId }`;

	const response = await XHR.get ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getDiamondRedeemActionAllowedStatus = async function () {

	const response = await XHR.get.bind ( this ) ( {
		url     : `${ configs.USER_API_URL }marketplace/actionAllowed?action=paytmDiamondsRedeemPayout`,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getUserDetailsForDonation = async function ( id ) {
	const response = await XHR.get.bind ( this ) ( {
		url: `${ configs.USER_API_URL }marketplace/anonymous/userDetails/${ id }`
	} );

	return response;
};

export const getUserDonations = async function ( streamerId, donatedAfter, pageNo ) {
	const response = await XHR.get ( {
		url: `${ configs.USER_API_URL }marketplace/anonymous/moneyDonors/${ streamerId }?pageNo=${ pageNo }&pageSize=10&sortBy=AMOUNT&donatedAfter=${ donatedAfter }&transactionModeId=1`
	} );

	return response;
};

export const getGemLogs = async function ( { userId, type, pageNo, pageSize } ) {
	if ( !userId )
		return null;

	const response = await XHR.get ( {
		url: `${ configs.USER_API_URL }user-profile/${ userId }/gems?pageNo=${ pageNo }&pageSize=${ pageSize }&type=${ type }`
	} );

	return response;
};

export async function getMyUserProfile ( userProfileParams ) {

	const response = await XHR.get.bind ( this ) ( {
		url    : `${ configs.USER_API_URL }user-profile/me`,
		params : userProfileParams ? userProfileParams : {}
	} );

	return response;
}
